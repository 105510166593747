<template>
  <div class="gestion-categorie-th">
    <div class="box-setting-header">
      <div class="title loaderPosition">
        <div class="widthTitle">Gestion des catégories</div>
        <div v-if="loadingForFetch" class=" loading ">
          <div class="spinner-border loadingFetch" role="status"></div>
        </div>
      </div>

      <div>
        <b-button
          variant="success"
          class="config-btn ml-2"
          v-b-modal.addCategories
        >
          <font-awesome-icon icon="plus" /> Ajouter une catégorie
        </b-button>
        <b-modal
          id="addCategories"
          ref="addCategories"
          :hide-footer="true"
          :hide-header="true"
          no-close-on-backdrop
          @hidden="resetModal"
          modal-class="cutsom-modal-bootstrap "
        >
          <div class="hader mb-2">
            <div class="titleSetting">Ajouter une catégorie</div>
            <div class="iconClose" @click.prevent="hideModal('addCategories')">
              <font-awesome-icon icon="times" />
            </div>
          </div>
          <Card>
            <template v-slot:body>
              <form
                @submit.prevent="handleSubmitCategorie"
                class="form-modal-custom-style"
              >
                <b-form-group
                  label="Nom de catégorie"
                  label-for="nom"
                  class="input-modal-champ "
                >
                  <b-form-input
                    autocomplete="off"
                    id="nom_categorie"
                    v-model="nom_categorie"
                    required
                  ></b-form-input>
                </b-form-group>
                <div class="actionModel mt-3">
                  <div class="messageError">
                    <div v-if="error" class="error">
                      <ul
                        v-if="Array.isArray(error)"
                        style="list-style-type: none;"
                      >
                        <li v-for="(e, index) in error" :key="index">
                          {{ e }}
                        </li>
                      </ul>
                      <div v-else>{{ error }}</div>
                    </div>
                  </div>
                  <b-button type="submit" class="button-valide-style ">
                    <span>
                      Valider
                      <div v-if="loading" class="loading ml-2">
                        <div class="spinner-border" role="status"></div>
                      </div>
                    </span>
                  </b-button>
                </div>
              </form>
            </template>
          </Card>
        </b-modal>
      </div>
    </div>
    <div class="ligne-box-setting-header"></div>
    <div class="body-box-setting">
      <div class="table-rapport-style w-100-p" style="width:100%">
        <b-table
          show-empty
          id="my-table"
          class="custom-table-style table-gestion-categories-style"
          :items="getCategories"
          :fields="fields"
          bordered
          sticky-header
          hover
          head-variant="light"
          tbody-tr-class="ligneNormale"
          empty-text="Il n'y a aucun enregistrement à afficher"
        >
          <template v-slot:cell(nom_categorie)="data" class="nomCategorieSize">
            {{ data.item.nom_categorie }}
          </template>
          <template v-slot:cell(Actions)="data" class="actionCategorieSize">
            <b-button
              class="button-danger-style m-2"
              size="sm"
              variant="danger"
              @click.prevent.stop="deleteCurrentCategorie(data.item)"
            >
              <font-awesome-icon icon="trash" />
            </b-button>
            <b-button
              class="button-danger-style m-2"
              size="sm"
              variant="success"
              @click.prevent.stop="updateCurrentCategorie(data.item)"
            >
              <font-awesome-icon icon="pencil-alt" />
            </b-button>
          </template>
        </b-table>
        <div class="footer-style mt-2">
          <b-pagination
            v-model="page"
            :total-rows="getTotalRowCategories"
            :per-page="perPage"
            aria-controls="my-table"
            pills
            align="center"
            size="sm"
            @change="pagination"
            class="pagination-style"
          ></b-pagination>

          <div class="per-page-element-style">
            <div class="box-label-champ">
              <div class="label-box-style">
                <span class="title-tabel">Eléments par page</span>
              </div>
            </div>
            <b-form-select
              v-model="perPage"
              :options="perPageList"
              @change="changePerPage"
              class="b-form-select-new-style bg-select"
            ></b-form-select>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      ref="deleteCategorie"
      id="deleteCategorie"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal()"
      modal-class="cutsom-modal-bootstrap "
    >
      <div class="hader mb-2">
        <div class="titleSetting">Supprimer une catégorie</div>
        <div class="iconClose" @click.prevent="hideModal('deleteCategorie')">
          <font-awesome-icon icon="times" />
        </div>
      </div>

      <form
        @submit.prevent="handleDeleteCategorie"
        class="form-modal-custom-style"
      >
        <div class="mt-4 ml-3">
          <p>
            Êtes-vous sur de vouloir supprimer cette catégorie?
          </p>
        </div>
        <div class="actionModel">
          <div class="messageError">
            <div v-if="error" class="error">
              <ul v-if="Array.isArray(error)">
                <li v-for="(e, index) in error" :key="'error' + index">
                  {{ e }}
                </li>
              </ul>
              <div v-else>{{ error }}</div>
            </div>
          </div>
          <b-button type="submit" class="button-valide-style mt-1">
            <span>
              Valider
              <div v-if="loading" class="loading ml-2">
                <div class="spinner-border" role="status"></div>
              </div>
            </span>
          </b-button>
        </div>
      </form>
    </b-modal>
    <b-modal
      ref="updateCategorie"
      id="updateCategorie"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal()"
      modal-class="cutsom-modal-bootstrap "
    >
      <div class="hader mb-2">
        <div class="titleSetting">Modifier une catégorie</div>
        <div class="iconClose" @click.prevent="hideModal('updateCategorie')">
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <form
        @submit.prevent="handleUpdateCategorie"
        class="form-modal-custom-style"
      >
        <b-form-group
          label="Nom de catégorie"
          label-for="nom"
          class="input-modal-champ "
        >
          <b-form-input
            autocomplete="off"
            id="nom_categorie"
            v-model="nom_categorie"
            required
          ></b-form-input>
        </b-form-group>
        <div class="actionModel mt-3">
          <div class="messageError">
            <div v-if="error" class="error">
              <ul v-if="Array.isArray(error)" style="list-style-type: none;">
                <li v-for="(e, index) in error" :key="index">
                  {{ e }}
                </li>
              </ul>
              <div v-else>{{ error }}</div>
            </div>
          </div>
          <b-button type="submit" class="button-valide-style ">
            <span>
              Valider
              <div v-if="loading" class="loading ml-2">
                <div class="spinner-border" role="status"></div>
              </div>
            </span>
          </b-button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'Gestion-des-catégories',
  data() {
    return {
      nom_categorie: null,
      error: null,
      loadingForFetch: false,
      loading: false,
      nameToUpdate: null,
      perPage: 10,
      page: 1,
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 }
      ],
      fields: [
        {
          key: 'nom_categorie',
          label: 'Nom catégorie',
          thStyle: { width: '100%' }
        },
        { key: 'Actions', label: 'Actions' }
      ],
      categorieToDelete: null,
      categorieToUpdate: null
    };
  },
  computed: {
    ...mapGetters([
      'getCategories',
      'getCategoriesIsLoading',
      'categoriesError',
      'getCategoriesDetailsLoading',
      'getTotalRowCategories'
    ])
  },
  methods: {
    ...mapActions([
      'fetchAllCategories',
      'addNewCategorie',
      'deleteCategory',
      'updateCategorie'
    ]),
    resetModal() {
      this.nom_categorie = null;
      this.error = null;
      this.loading = false;
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    async changePerPage() {
      this.loadingForFetch = true;
      this.page = 1;
      await this.fetchAllCategories({
        per_page: this.perPage,
        page: this.page
      });
      this.loadingForFetch = false;
    },
    async pagination(paginate) {
      this.loadingForFetch = true;
      this.page = paginate;
      await this.fetchAllCategories({
        per_page: this.perPage,
        page: this.page
      });
      this.loadingForFetch = false;
    },

    async handleSubmitCategorie() {
      this.loading = true;
      const response = await this.addNewCategorie({
        nom_categorie: this.nom_categorie
      });
      if (response) {
        this.loading = false;
        this.hideModal('addCategories');
      } else {
        this.error = this.categoriesError;
        this.loading = false;
      }
    },
    deleteCurrentCategorie(data) {
      this.categorieToDelete = data;
      this.$refs['deleteCategorie'].show();
    },

    async handleDeleteCategorie() {
      this.loading = true;
      const response = await this.deleteCategory(this.categorieToDelete);
      if (response) {
        this.loading = false;
        this.hideModal('deleteCategorie');
        if (this.getCategories.length === 0 && this.page > 1) {
          let newPage = this.page - 1;
          this.pagination(newPage);
        }
      } else {
        this.loading = false;
      }
    },
    updateCurrentCategorie(data) {
      this.nom_categorie = data.nom_categorie;
      this.categorieToUpdate = data;
      this.$refs['updateCategorie'].show();
    },
    async handleUpdateCategorie() {
      this.loading = true;
      const response = await this.updateCategorie({
        categorie: this.categorieToUpdate,
        nom_categorie: this.nom_categorie
      });
      if (response) {
        this.loading = false;
        this.hideModal('updateCategorie');
      } else {
        this.loading = false;
        this.error = this.categoriesError;
      }
    }
  },
  components: {
    Card: () => import('../component/card')
  },
  async mounted() {
    this.loadingForFetch = true;
    await this.fetchAllCategories({ per_page: this.perPage, page: this.page });
    this.loadingForFetch = false;
  }
};
</script>

<style lang="scss" scoped>
.gestion-categorie-th {
  height: 100%;
  .tabs-categorie {
    width: 25%;
    margin: 5px 15px;
    .custom-list-group {
      .list-group-item {
        justify-content: space-between;
        color: #515356;
        display: block;
        font-weight: 600;
        .list-item {
          justify-content: space-between;
        }
      }
    }
    .title {
      color: #515356;
      display: block;
      font-weight: 600;
      margin: 0px 0px 10px 0px;
    }
    .categorie-labels {
      display: flex;
      justify-content: space-between;
    }
    .trash-style {
      margin-left: 5px;
      color: #dc3545;
    }
  }

  .categorie-body {
    display: flex;
    margin: 5px 15px;
    height: calc(100vh - 86px);
    .categorie-details {
      width: 75%;
      .title {
        color: #515356;
        display: block;
        font-weight: 600;
        margin: 5px;
      }
      .input-modal-champ {
        input {
          border-radius: 5px;
        }
      }
      .custom-table-style {
        padding: 5px;
        max-height: calc(100vh - 198px);
        height: calc(100% - 198px);
        width: 100%;
      }
    }
  }
  .ligne-box-setting-header {
    margin-top: 1%;
  }
  .table-gestion-categories-style {
    max-height: calc(100vh - 247px) !important;
    height: calc(100vh - 247px) !important;
    margin-bottom: 0px;
    max-width: 100%;
  }
}
.loadingFetch {
  width: 16px;
  height: 16px;
  font-size: 10px;
}
.widthTitle {
  width: 180px;
}
.loaderPosition {
  display: flex;
  flex-direction: row;
}
</style>
